<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">积分兑换记录</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="老师姓名" prop="teacherName">
        <el-input type="text" size="mini" v-model="queryModel.teacherName"></el-input>
      </el-form-item>
      <el-form-item label="所属学校" prop="schoolId">
          <el-select v-model="queryModel.schoolId" filterable placeholder="请选择" size="mini">
          <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          ></el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="提现状态" prop="reflectStatus">
        <el-select v-model="queryModel.reflectStatus" filterable  size="mini" placeholder="请选择" >
            <el-option
            v-for="item in reflectStatusList"
            :key="item.value"
            :label="item.name"
            :value="item.value">
            </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="积分来源" prop="scenes">
        <el-select
          v-model="queryModel.scenes"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:100px"
        >
          <el-option v-for="item in sceneList" :key="item.id" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="积分兑换时间">
        <el-date-picker
            v-model="queryModel.redeemTimeRange"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="选择时间范围"
            value-format="yyyy-MM-dd"
            >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div class="button-group">
      <div class="title">积分兑换列表</div>
      <el-button type="success" size="small" plain icon="el-icon-download" @click="exportXls()">批量导出</el-button>
    </div>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
    >
      <el-table-column prop="teacherName" label="老师姓名" :sortable="false" width="150"></el-table-column>
      <el-table-column prop="schoolName" label="学校" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="className" label="班级" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="points" label="兑换积分" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="amount" label="兑换金额(元)" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="reflectTime" label="积分兑换时间" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="reflectStatus" label="提现状态" :sortable="false" width="130">
        <template slot-scope="{row}">
          <span v-if="row.reflectStatus=='ok'" >
              提现成功
          </span>
          <span v-else>提现失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="备注" :sortable="false" width="180"></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Constant from "@/constant";
import cumulativePointsApi from "@/api/business/cumulativePointsApi";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import detentionWarningApi from '@/api/bus/detentionWarning'

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "business-redeemPoints-list",
  data() {
    var self = this;

    return {
      queryModel: {
        teacherName: "",
        redeemTimeRange: null,
        reflectStatus:"ok",
        schoolId:"",
      },
      schoolList:[],
      loading: false,
      tableData: [],
      reflectStatusList:[
        {value:"ok",name:"提现成功"},
        {value:"error",name:"提现失败"}
        ],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      pageSizeList: [10, 20, 30],
      sceneList: [],
    }
  },
  created() {
    // var formData = new FormData();
    // formData.append("catalogName", "积分使用场景");

    // dataDictionaryApi.findByCatalogName(formData).then((response)=>{
    //   var jsonData = response.data;
    //   this.sceneList = jsonData.data;
    // });

    detentionWarningApi.schoolList().then(response => {
        var jsonData = response.data;
        this.schoolList = jsonData.data;
    });

    this.changePage(1);
  },
  methods: {
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    changePage(pageIndex,exportFlag) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("useType", "1");
      formData.append("pageIndex", self.pageIndex);

      if(self.queryModel.teacherName!=null){
        formData.append("teacherName", self.queryModel.teacherName);
      }

      if(self.queryModel.redeemTimeRange!=null) {
        if(self.queryModel.redeemTimeRange[0] != null){
          formData.append("redeemBeginTime", self.queryModel.redeemTimeRange[0]);
        }

        if(self.queryModel.redeemTimeRange[1] != null){
          formData.append("redeemEndTime", self.queryModel.redeemTimeRange[1]);
        }
      }

      if(exportFlag!=null){
        formData.append("exportFlag", exportFlag);
        formData.append("pageSize", 10000);
      }
      else{
        formData.append("pageSize", self.pageSize);
      }

      formData.append("reflectStatus",self.queryModel.reflectStatus);0
      formData.append("schoolId",self.queryModel.schoolId);

      cumulativePointsApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data;

          if(jsonData.result) {
            if(exportFlag){
              //导出
              self.$message({
                showClose: true,
                type: "success",
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 30000
              });
            }
            else{
              //分页查看
              var page = jsonData.data;

              self.tableData = page.data;
              self.totalPages = page.totalPages;
              self.totalElements = page.recordsTotal;
            }
          }
          else{
            self.$message.warning(jsonData.message);
          }
        })
        .catch(error => {
          self.loading = false;
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.changePage(1);
    },
    exportXls() {
      this.changePage(1,2);
    }
  }
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
  box-sizing:border-box;
  flex-direction: row;
  justify-content: space-between;
  width:100%;

  .title{
    font-size:16px;
    font-weight: bold;
  }
}
</style>